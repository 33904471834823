@import '../variables.scss';

.calc {
  display: block;
  margin: 0 auto;
  max-width: 60rem;
  @include prevent-text-selection;

  &__above,
  &__below {
    display: flex;
    align-items: center;
  }

  &__above {
    justify-content: center;
    flex-direction: column;
    font-size: $font-md;
  }

  &__below {
    justify-content: space-between;
    font-size: $font-xs;
    align-items: flex-start;
  }

  &__body {
    padding: 1rem 3rem 3rem;
    margin: 5px 0;
    // border: 2px solid black;
    // border-radius: 10px;
    @include neu-shadow;

    @media only screen and (max-width: 800px) {
      box-shadow: none;
      margin: 0;
      padding: 1rem;
    }
  }

  &__meta {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-lg;
  }

  &__target,
  &__moves {
    margin: 1rem 2rem;
  }

  &__functions,
  &__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 0;
  }
}

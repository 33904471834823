@import '../variables.scss';

.signed-in {
  &__btn {
    display: inline-block;
    margin-left: 2rem;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-out;
    background: transparent;
  }

  &__img {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    padding: 2px;
    border: 2px solid $accent-3;
    background: transparent;
  }

  &:hover,
  &:focus-visible {
    transform: scale(1.1, 1.1);
  }
}

.opted-out {
  &__btn {
    margin: 0 2rem;
    padding: 0.5rem 1rem;
    border: 1px solid $accent-3;
    border-radius: 4px;
    font-size: 1.8rem;
    text-decoration: none;
    color: black;
    transition: all 0.2s;

    &:hover,
    &:focus-visible {
      background-color: $accent-3;
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }
  }
}

@import '../variables.scss';

.goal-list {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.goal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem 1rem;
  width: 6rem;
}

.goal-desc {
  text-align: center;
  font-size: $font-xl;
  line-height: 1;

  @media only screen and (max-width: 800px) {
    font-size: $font-lg;
  }
}

.star-icon {
  font-family: sans-serif;
  font-size: $font-lg;
  margin: 1px;
  text-align: center;
  color: $grey-light;

  @media only screen and (max-width: 500px) {
    font-size: $font-md;
  }
}

.star-success {
  color: $accent-3;
}

@import '../variables.scss';

.success-modal {
  padding: 3rem;
  @include prevent-text-selection;

  @media only screen and (max-width: 500px) {
    padding: 1rem;
  }

  &__container {
    border-radius: 5px;
  }

  &__close-btn {
    &:focus,
    &:hover {
      fill: $accent-1;
      outline: none;
    }
  }

  &__title {
    font-size: 5rem;
    font-weight: bold;
    text-align: center;
    margin: 2rem 0;

    @media only screen and (max-width: 500px) {
      font-size: 3.5rem;
      margin: 0;
    }
  }

  &__body {
    text-align: center;
    font-size: 1.8rem;
  }

  &__msg-hist-container {
    margin: 3rem 0;

    & > div {
      margin: 1rem 0;
    }
  }

  &__msg {
  }

  &__hist {
    color: $grey-dark;
    font-size: 2.4rem;

    @media only screen and (max-width: 500px) {
      font-size: 2.2rem;
    }
  }

  &__goals {
    justify-content: center;
    margin: 2rem 0;
  }

  &__btn {
    font-family: inherit;
    font-size: 2rem;
    padding: 1.5rem 2rem;
    background-color: transparent;
    outline: none;
    border: 2px solid black;
    border-radius: 5px;
    margin: 3rem;
    cursor: pointer;

    &:hover,
    &:focus-visible {
      color: white;
      background-color: black;
    }

    @media only screen and (max-width: 500px) {
      font-size: 1.8rem;
      margin: 1rem;
      border-width: 1px;
    }
  }
}

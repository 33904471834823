@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,600;1,400&display=swap);
*,*::before,*::after{box-sizing:inherit}html{box-sizing:border-box;font-size:62.5%;padding:0;margin:0}@media only screen and (max-width: 1000px){html{font-size:58%}}@media only screen and (max-width: 700px){html{font-size:52%}}@media only screen and (max-width: 450px){html{font-size:48%}}body{font-family:'Roboto Mono', monospace;font-weight:400;line-height:1.6;margin:0;background-color:#f0f0f0}#root{margin:1rem;padding:0}

.header{display:flex;justify-content:space-between;align-items:center;font-size:2rem;margin:1rem auto;max-width:120rem}.header__right{display:inline-flex;align-items:center}.header__total-stars{margin-left:1rem}.header__star{color:#f9d820;font-size:2.5rem;font-family:sans-serif;margin:1rem}

.account-modal{padding:3rem}.account-modal__container{border-radius:5px}.account-modal__close-btn:focus,.account-modal__close-btn:hover{fill:#eb4f4f;outline:none}.account-modal__img{display:block;margin:0 auto;padding:3px;border:3px solid #f9d820;border-radius:50%}.account-modal__title{font-size:3rem;font-weight:bold;text-align:center;margin:2rem 0}.account-modal__body{text-align:center;font-size:1.8rem}.account-modal__btn{display:block;width:100%;font-family:inherit;font-size:2rem;padding:1.5rem 2rem;background-color:transparent;outline:none;border:2px solid black;border-radius:5px;margin:3rem auto;cursor:pointer}.account-modal__btn:hover,.account-modal__btn:focus-visible{color:white;background-color:black}.account-modal__star{color:#f9d820;font-family:sans-serif;margin:0 1rem}

.signed-in__btn{display:inline-block;margin-left:2rem;padding:0;border:none;outline:none;cursor:pointer;transition:all 0.2s ease-out;background:transparent}.signed-in__img{height:5rem;width:5rem;border-radius:50%;padding:2px;border:2px solid #f9d820;background:transparent}.signed-in:hover,.signed-in:focus-visible{transform:scale(1.1, 1.1)}.opted-out__btn{margin:0 2rem;padding:0.5rem 1rem;border:1px solid #f9d820;border-radius:4px;font-size:1.8rem;text-decoration:none;color:black;transition:all 0.2s}.opted-out__btn:hover,.opted-out__btn:focus-visible{background-color:#f9d820;transform:translateY(-2px)}.opted-out__btn:active{transform:translateY(1px)}

.goal-list{display:flex;width:100%;justify-content:space-evenly}.goal{display:flex;flex-direction:column;justify-content:space-between;margin:0.5rem 1rem;width:6rem}.goal-desc{text-align:center;font-size:3rem;line-height:1}@media only screen and (max-width: 800px){.goal-desc{font-size:2.4rem}}.star-icon{font-family:sans-serif;font-size:2.4rem;margin:1px;text-align:center;color:#cdcdcd}@media only screen and (max-width: 500px){.star-icon{font-size:2rem}}.star-success{color:#f9d820}

.success-modal{padding:3rem;-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}@media only screen and (max-width: 500px){.success-modal{padding:1rem}}.success-modal__container{border-radius:5px}.success-modal__close-btn:focus,.success-modal__close-btn:hover{fill:#eb4f4f;outline:none}.success-modal__title{font-size:5rem;font-weight:bold;text-align:center;margin:2rem 0}@media only screen and (max-width: 500px){.success-modal__title{font-size:3.5rem;margin:0}}.success-modal__body{text-align:center;font-size:1.8rem}.success-modal__msg-hist-container{margin:3rem 0}.success-modal__msg-hist-container>div{margin:1rem 0}.success-modal__hist{color:#505050;font-size:2.4rem}@media only screen and (max-width: 500px){.success-modal__hist{font-size:2.2rem}}.success-modal__goals{justify-content:center;margin:2rem 0}.success-modal__btn{font-family:inherit;font-size:2rem;padding:1.5rem 2rem;background-color:transparent;outline:none;border:2px solid black;border-radius:5px;margin:3rem;cursor:pointer}.success-modal__btn:hover,.success-modal__btn:focus-visible{color:white;background-color:black}@media only screen and (max-width: 500px){.success-modal__btn{font-size:1.8rem;margin:1rem;border-width:1px}}

.screen{padding:2rem 1.5rem 1rem;border:1px solid black;background-color:lightgray}.screen__value{font-size:6rem;text-align:right;line-height:1.3}.screen__history{font-size:1.5rem;text-align:right;line-height:1.3;color:#8c8c8c}.screen__history::before{content:'a';color:transparent}

.meta{text-align:center;align-items:center;margin:1rem 0;font-size:3rem}.meta__block{color:#eb4f4f}

.calc-btn{position:relative;margin:0.75rem;border:2px solid #d7d7d7;border-radius:5px;width:15rem;transition:all 0.3s;padding:1px;display:flex;align-items:center}.calc-btn:hover:not(.calc-btn--disabled){border-color:transparent;box-shadow:6px 6px 15px #cdcdcd,-6px -6px 15px #fff}.calc-btn:active:not(.calc-btn--disabled){box-shadow:inset 4px 4px 8px #cdcdcd,inset -4px -4px 8px #fff}.calc-btn__btn{cursor:pointer;width:100%;padding:1.2rem;border:none;outline:none;font-size:2.4rem;font-family:inherit;background-color:transparent}.calc-btn__btn:focus-visible{color:#eb4f4f}.calc-btn__btn--disabled{color:#cdcdcd}.calc-btn--reset{color:#eb4f4f;border-color:#eb4f4f}.calc-btn__uses-left{position:absolute;left:50%;bottom:0;line-height:0;font-size:1.8rem;color:#eb4f4f;transform:translate(-50%, 50%)}.calc-btn__uses-left--infinity{display:none}

.spinner{display:flex;justify-content:center;align-items:center;height:50vh}.spinner svg{fill:#8c8c8c;stroke:#8c8c8c}

.move-count{text-align:right;font-size:1.8rem}

.difficulty{text-align:right}.difficulty__emoji{font-size:3rem}

.calc{display:block;margin:0 auto;max-width:60rem;-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}.calc__above,.calc__below{display:flex;align-items:center}.calc__above{justify-content:center;flex-direction:column;font-size:2rem}.calc__below{justify-content:space-between;font-size:1.6rem;align-items:flex-start}.calc__body{padding:1rem 3rem 3rem;margin:5px 0;box-shadow:6px 6px 15px #cdcdcd,-6px -6px 15px #fff}@media only screen and (max-width: 800px){.calc__body{box-shadow:none;margin:0;padding:1rem}}.calc__meta{display:flex;justify-content:center;align-items:center;font-size:2.4rem}.calc__target,.calc__moves{margin:1rem 2rem}.calc__functions,.calc__controls{display:flex;flex-wrap:wrap;justify-content:center;margin:2rem 0}

.background{height:100vh;max-height:160rem;width:100%;display:flex;justify-content:center;align-items:center}.centered{display:flex;justify-content:center;align-items:center;flex-direction:column;text-align:center;max-width:60rem}.title-container{margin-bottom:5rem}.title{font-size:6.2rem;text-transform:uppercase;margin:0;line-height:1;margin:2rem}@media only screen and (max-width: 800px){.title{font-size:5rem}}.subtitle{font-size:2.2rem}@media only screen and (max-width: 800px){.subtitle{font-size:1.8rem}}.login{display:flex;flex-direction:column;justify-content:center;font-size:1.8rem;padding:0 1rem}.login__auth-btn{display:block;font-size:1.8rem;font-family:inherit;border:1px solid black;margin:1.5rem 0;padding:1rem;text-decoration:none;color:black;cursor:pointer;transition:all 0.2s;text-align:center;background-color:transparent}.login__auth-btn:hover{color:white;background-color:black}.login__icon{height:3rem;width:auto;vertical-align:bottom;margin-right:2rem}.accent-color{color:#f9d820;font-weight:bold}.note{color:#8c8c8c;text-align:left;font-size:1.6rem}

.lvl-page{display:flex;flex-direction:column;justify-content:center;align-items:flex-start;max-width:80rem;margin:0 auto}.lvl-page__header{margin:3rem 0}.lvl-page__title{font-size:5rem;font-weight:600}.lvl-page__desc{font-size:2rem}.lvl-page__list-of-puzzles{display:flex;justify-content:left;flex-wrap:wrap;width:100%}.lvl-page__puz-and-star-wrapper{width:10rem;margin:1rem 0.5rem;text-align:center;display:flex;flex-direction:column;align-items:center;justify-content:center}.lvl-page__puzzle-btn{height:5rem;width:5rem;color:#292929;border-radius:50%;display:flex;justify-content:center;align-items:center;font-size:2rem;outline:none;border:2px solid #cdcdcd;cursor:pointer;transition:all 0.3s;background-color:transparent}.lvl-page__puzzle-btn:hover,.lvl-page__puzzle-btn:focus{border-color:transparent;box-shadow:6px 6px 15px #cdcdcd,-6px -6px 15px #fff}.lvl-page__puzzle-btn:active{border-color:transparent;box-shadow:inset 4px 4px 8px #cdcdcd,inset -4px -4px 8px #fff}.lvl-page__stars{font-size:2rem;text-align:center;font-family:sans-serif;margin-top:0.5rem}.lvl-page__star{color:#8c8c8c}.lvl-page__star--earned{color:#f9d820}.lvl-page__nav{display:flex;justify-content:space-between;align-items:center;width:100%;margin:5rem 0}.lvl-page__mid{flex-grow:1;text-align:center;font-size:2rem}.lvl-page__level-btn{padding:1rem 2rem;border-radius:5px;border:2px solid #cdcdcd;display:flex;justify-content:center;align-items:center;font-size:2rem;outline:none;color:#292929;background-color:transparent;cursor:pointer}.lvl-page__level-btn:hover,.lvl-page__level-btn:focus{border-color:transparent;box-shadow:6px 6px 15px #cdcdcd,-6px -6px 15px #fff}.lvl-page__level-btn:active{border-color:transparent;box-shadow:inset 4px 4px 8px #cdcdcd,inset -4px -4px 8px #fff}@media only screen and (max-width: 800px){.lvl-page__level-btn{border-width:1px}}

.privacy{max-width:80rem;margin:5rem auto;font-size:2rem}.privacy>p{line-height:2}.privacy>p:not(:last-of-type){margin-bottom:4rem}


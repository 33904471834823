@import '../variables.scss';

.calc-btn {
  position: relative;
  margin: 0.75rem;
  border: 2px solid $grey-pretty-light;
  border-radius: 5px;
  width: 15rem;
  transition: all 0.3s;
  padding: 1px;
  display: flex;
  align-items: center;

  &:hover:not(.calc-btn--disabled) {
    border-color: transparent;
    @include neu-shadow;
  }

  &:active:not(.calc-btn--disabled) {
    @include neu-shadow-inset;
  }

  &__btn {
    cursor: pointer;
    width: 100%;
    padding: 1.2rem;
    border: none;
    outline: none;
    font-size: $font-lg;
    font-family: inherit;
    background-color: transparent;

    &:focus-visible {
      color: $accent-1;
    }

    &--disabled {
      color: $grey-light;
    }
  }

  &--ctrl {
  }

  &--reset {
    color: $accent-1;
    border-color: $accent-1;
  }

  &__uses-left {
    position: absolute;
    left: 50%;
    bottom: 0;
    line-height: 0;
    font-size: $font-sm;
    color: $accent-1;
    transform: translate(-50%, 50%);

    &--infinity {
      display: none;
    }
  }
}

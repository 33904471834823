// COLORS
$grey-very-light: #f0f0f0;
$grey-pretty-light: #d7d7d7;
$grey-light: #cdcdcd;
$grey: #8c8c8c;
$grey-dark: #505050;
$grey-very-dark: #292929;

$accent-1: #eb4f4f;
$accent-2: #4f8deb;
$accent-3: #f9d820;

// Fonts
$font-xl: 3rem;
$font-lg: 2.4rem;
$font-md: 2rem;
$font-sm: 1.8rem;
$font-xs: 1.6rem;

// MIXINS
@mixin neu-shadow {
  box-shadow: 6px 6px 15px $grey-light, -6px -6px 15px #ffffff;
}

@mixin neu-shadow-inset {
  box-shadow: inset 4px 4px 8px $grey-light, inset -4px -4px 8px #ffffff;
}

@mixin prevent-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@import '../variables.scss';

.screen {
  padding: 2rem 1.5rem 1rem;
  border: 1px solid black;
  background-color: lightgray;

  &__value {
    font-size: 6rem;
    text-align: right;
    line-height: 1.3;
  }

  &__history {
    font-size: 1.5rem;
    text-align: right;
    line-height: 1.3;
    color: $grey;

    &::before {
      content: 'a';
      color: transparent;
    }
  }
}

@import '../variables.scss';

.background {
  height: 100vh;
  max-height: 160rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 60rem;
}

.title-container {
  margin-bottom: 5rem;
}

.title {
  font-size: 6.2rem;
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
  margin: 2rem;

  @media only screen and (max-width: 800px) {
    font-size: 5rem;
  }
}

.subtitle {
  font-size: 2.2rem;

  @media only screen and (max-width: 800px) {
    font-size: 1.8rem;
  }
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.8rem;
  padding: 0 1rem;

  &__auth-btn {
    display: block;
    font-size: 1.8rem;
    font-family: inherit;
    border: 1px solid black;
    margin: 1.5rem 0;
    padding: 1rem;
    text-decoration: none;
    color: black;
    cursor: pointer;
    transition: all 0.2s;
    text-align: center;
    background-color: transparent;

    &:hover {
      color: white;
      background-color: black;
    }
  }

  &__icon {
    height: 3rem;
    width: auto;
    vertical-align: bottom;
    margin-right: 2rem;
  }
}

.accent-color {
  color: $accent-3;
  font-weight: bold;
}

.note {
  color: $grey;
  text-align: left;
  font-size: 1.6rem;
}

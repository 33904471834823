// Import Roboto Mono font family
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,600;1,400&display=swap');
@import './variables.scss';

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
  padding: 0;
  margin: 0;

  @media only screen and (max-width: 1000px) {
    font-size: 58%;
  }

  @media only screen and (max-width: 700px) {
    font-size: 52%;
  }

  @media only screen and (max-width: 450px) {
    font-size: 48%;
  }
}

body {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  background-color: $grey-very-light;
}

#root {
  margin: 1rem;
  padding: 0;
}

@import '../variables.scss';

.account-modal {
  padding: 3rem;

  &__container {
    border-radius: 5px;
  }

  &__close-btn {
    &:focus,
    &:hover {
      fill: $accent-1;
      outline: none;
    }
  }

  &__img {
    display: block;
    margin: 0 auto;
    padding: 3px;
    border: 3px solid $accent-3;
    border-radius: 50%;
  }

  &__title {
    font-size: $font-xl;
    font-weight: bold;
    text-align: center;
    margin: 2rem 0;
  }

  &__body {
    text-align: center;
    font-size: $font-sm;
  }

  &__btn {
    display: block;
    width: 100%;
    font-family: inherit;
    font-size: $font-md;
    padding: 1.5rem 2rem;
    background-color: transparent;
    outline: none;
    border: 2px solid black;
    border-radius: 5px;
    margin: 3rem auto;
    cursor: pointer;

    &:hover,
    &:focus-visible {
      color: white;
      background-color: black;
    }
  }

  &__star {
    color: $accent-3;
    font-family: sans-serif;
    margin: 0 1rem;
  }
}
